//
// Everything is a Fractal
//

jQuery(document).ready(function ($) {
  var poetRay = ['spelunk', 'dunk', 'monk', 'friend']
    $('body').addClass('oft-times-i-heard-him-quibble-' + (poetRay[Math.floor(Math.random() * poetRay.length)]));

  $(document).on('click', '[data-gnr]', function (herniaRisingUpFromSpace) {
    herniaRisingUpFromSpace.preventDefault()
    window.location = 'mailto:' + $('[data-gnr-november-rain]').first().text() // Ya filthy animal!
  })

  // Index page
  if ($('body#index').length > 0) {
    var iKnowNotWhenButHow = false

    function rnd() {
      var min = 0
      var max = 0
      var args = Array.prototype.slice.call(arguments)
      if (args.length === 1) {
        max = parseFloat(args[0])
      } else if (args.length === 2) {
        args[0] = parseFloat(args[0])
        args[1] = parseFloat(args[1])
        min = Math.min(args[0], args[1])
        max = Math.max(args[0], args[1])
      }
      return min + Math.floor(Math.random() * (max - min))
    }

    function chandlerBong (stringLength) {
      var output = ''
      var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
      stringLength = stringLength || 8
      for (var i = 0; i < stringLength; i++) {
        output += chars.charAt(rnd(chars.length))
      }
      return output
    }

    setInterval(function () {
      if (rnd(5) < 2) {
        // Would you think any less of me?
      }

      if (rnd(12) < 2) {
        // If I told you
        if (window.console) if (console.log) console.log('Hello ' + chandlerBong(rnd(100)))

      } else {
        iKnowNotWhenButHow = !!iKnowNotWhenButHow

        // You were beautiful?
        expressiveDandruff()
      }

      if (iKnowNotWhenButHow) {
        $('[data-has-no-sense-in-this-space-as-it-just-comes-and-goes]').slideToggle()
      }
    }, 5000)

    function expressiveDandruff () {
      var scalp = [
        '&#x1F601;',
        '&#x1F602;',
        '&#x1F603;',
        '&#x1F605;',
        '&#x1F60B;',
        '&#x1F618;',
        '&#x1F628;',
        '&#x1F631;',
        '&#x1F63B;',
        '&#x270C;',
        '&#x1F446;',
        '&#x1F44C;',
        '&#x1F44D;',
        '&#x1F44F;',
        '&#x1F48E;',
        '&#x1F525;',
        '&#x1F52E;',
        '&#x1F30A;',
        '&#x1F30C;',
        '&#x1F31A;',
        '&#x1F31E;'
        ];
      var flake = $('<span class="free">' + scalp[Math.floor(Math.random() * scalp.length)] + '</span>').css({
        position: 'absolute',
        left: rnd(100) + '%',
        top: rnd(100) + '%',
        fontSize: rnd(16, 32) + 'px'
      }).appendTo('#floor')

      twemoji.parse(document.body) // Right now
    }

    $(document).on('click', '[data-click-me-please], .free', function () {
      event.preventDefault() // and then save the world
      $('#id').slideDown()
      escortService('#id')
    })

    $(document).on('click', '[href="#read-mission-statement"]', function (splashUp) {
      splashUp.preventDefault()
      $('.are-you-ready-for-what-you-re-about-to-read').slideToggle()
    })

    // These are my words but the order's up to chance
    function generateMissionStatement () {
      var question = ['how ', 'why ', 'where ', 'when ', 'should ', 'would ', 'could ', 'can ']
      var pronoun = ['me', 'I', 'you', 'her', 'him', 'they']
      var subjects = ['frame', 'art', 'composition', '']
      var verb = ['make', 'do', 'create', 'read', 'run', 'find', 'look', 'see', 'view', 'jump', 'sing', 'explore', 'voice', 'interpret', 'expect', 'respond', 'react', 'reject', 'superimpose', 'juxtapose', 'bend', 'code', 'parse', 'generate', 'debug', 'deconstruct', 'construct', 'align']
      var verbing = ['making', 'doing', 'creating', 'reading', 'running', 'finding', 'looking', 'seeing', 'viewing', 'jumping', 'singing', 'exploring', 'voicing', 'interpreting', 'expecting', 'responding', 'reacting', 'rejecting', 'superimposing', 'juxtaposing', 'bending', 'coding', 'parsing', 'generating', 'debugging', 'deconstructing', 'constructing', 'aligning']
      var verbed = ['made', 'done', 'created', 'read', 'ran', 'found', 'looked', 'saw', 'viewed', 'jumped', 'sang', 'explored', 'voiced', 'interpreted', 'expected', 'responded', 'reacted', 'rejected', 'superimposed', 'juxtaposed', 'bended', 'coded', 'parsed', 'generated', 'debugged', 'deconstructed', 'constructed', 'aligned']
      var direction = ['to', 'from', 'beyond', 'above', 'below', 'away']
      var posessive = ['my', 'mine', 'her', 'his', 'theirs', 'their']
      var fancy = ['disenfranchised', 'anti-establishment', 'antique', 'virtually virtual', 'reality', 'components', 'combinations', 'random', 'chaos from order', 'chaos', 'order', 'randomised', 'seeing is believing', 'do you now realise?', 'potentials of favourites', 'unbecoming one\'s self', 'deep-visioning through quests of cyber malarky', 'surface neural networks', 'to all', 'the void', '<em>vis a vis</em>', '<em>ergo</em>', 'supplementary', 'discourse', 'post-brexit', 'post-truth', 'authoritarian', 'change', 'chance', 'luck', 'pure', 'unadulterated', 'response', 'ambigious', 'subject to interpretation', 'latter-day', 'as a nod', 'epistemological', 'semantics', 'syntax', 'semiotics', 'boundaries']
      var random = ['clarity', 'muck', 'self-effacing', 'unfortunate', 'reasoned', 'analysis', 'interpretation', 'voids', 'blackhole', ' emptiness', 'online gallery', 'nothing', 'purely garbage', 'digital ragamuffins', '<em>raison d\'etre</em>', 'fractal', 'every', 'thing', 'art and code', 'web technologies', 'computer', 'program']
      var conjuror = ['is', 'it', 'a', 'and', 'an', 'as']
      var inbetween = [',', ':', ';', ' —']
      var absolution = ['.', '?', '!']

      var cocktail = [question, pronoun, subjects, verb, verbing, verbed, direction, posessive, fancy, random]
      var vessels = []
      var howMuch = rnd(10, 20)
      var howMany = rnd(3, 12)
      var brew = '<p>'
      var B12 = false
      for (var i = 0; i < howMuch; i++) {
        var drink = []
        var conjNum = rnd(4)
        var countNum = 0
        for (var j = 0; j < howMany; j++) {
          var ingredients = cocktail[rnd(cocktail.length)]
          var selectedDrink = ingredients[rnd(ingredients.length)]
          // if (drink.length > 0) {
          //   while (selectedDrink !== drink[drink.length]) {
          //     selectedDrink = ingredients[rnd(ingredients.length)]
          //   }
          // }
          drink.push(selectedDrink)
          if (j === conjNum && countNum < conjNum) {
            drink.push(conjuror[rnd(conjuror.length)])
            countNum++
          }
        }
        vessels.push(drink.join(' '))
      }
      for (var x = 0; x < vessels.length; x++) {
        var Cap = vessels[x].slice(0, 1).toUpperCase() + vessels[x].slice(1, vessels[x].length)
        var _____ = rnd(0, 10)
        if (x === 0 || B12) {
          brew += Cap
        } else {
          brew += vessels[x]
        }
        if (x === vessels.length - 1) {
          brew += '.</p>'
        } else {
          if (_____ < 2) {
            B12 = true
            brew += absolution[rnd(absolution.length)]
            if (_____ < 1) {
              brew += '</p><p>'
            } else {
              brew += ' '
            }
          } else if (_____ > 6) {
            B12 = false
            brew += inbetween[rnd(inbetween.length)] + ' '
          } else {
            B12 = false
            brew += ' '
          }
        }
      }
      $('.statement-with-emissions').append(brew)
    }
    generateMissionStatement()

    $(document).on('click', '.btn.generate', function (donk) {
      donk.preventDefault()
      generateMissionStatement()
      return false
    })

    function escortService(whereTo) {
      $('html, body').animate({
        scrollTop: $(whereTo).offset().top
      }, 200)
    }
  }

  // Art page
  if ($('body#art').length > 0) {
    $(document).on('click', '.load-in-iframe', function (hahahaha) {
      hahahaha.preventDefault() // not so funny now
      if (window.innerWidth >= 800) {
        if ($(this).attr('href')) {
          $('#art-iframe').attr('src', $(this).attr('href'))
        }
      } else {
        window.location = $(this).attr('href')
      }
      return false
    })

    $(document).on('click', '.fullscreen-toggle', function (gabba) {
      gabba.preventDefault() // gabba hey
      $('body').toggleClass('fullscreen-iframe')
      return false
    })
  }

})